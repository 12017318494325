























































import { Component, Vue, Watch } from "nuxt-property-decorator";
import { gsap } from "gsap";

@Component<MStatsBlocks>({})
export default class MStatsBlocks extends Vue {
  interval!: any;
  highlighted = 0;
  highlightClass = "page-stats-blocks__item--highlight";

  itemClass(id: number) {
    let classValue: { [key: string]: boolean } = {};
    classValue[this.highlightClass] = id === this.highlighted;

    return classValue;
  }

  isMounted = false;
  isVisible = false;

  defaultValues: { [key: string]: number } = {
    experience: 22,
    projects: 680,
    team: 6,
    yerba: 12
  };

  values = { ...this.defaultValues };

  tweenedValues = { ...this.values };

  countUp() {
    for (const key in this.values) {
      this.values[key] = this.defaultValues[key];
    }
  }

  @Watch("isVisible")
  onVisibilityChange(newValue: boolean, oldValue: boolean) {
    if (newValue && newValue !== oldValue) this.countUp();
  }

  @Watch("values", { deep: true })
  onValuesChange() {
    if (this.isMounted) {
      gsap.to(this.tweenedValues, { duration: 3, ...this.values });
    }
  }

  onViewportChange() {
    if (this.$refs.root) {
      const rect = (this.$refs.root as HTMLElement).getBoundingClientRect();

      const visible =
        rect.top >= 0 - rect.height / 2 &&
        rect.left >= 0 - rect.width / 2 &&
        rect.bottom <=
          (window.innerHeight || document.documentElement.clientHeight) +
            rect.height / 2 &&
        rect.right <=
          (window.innerWidth || document.documentElement.clientWidth) +
            rect.width / 2;

      if (visible) this.isVisible = true;
    }
  }

  mounted() {
    this.interval = setInterval(this.changeHighlight, 3000);

    for (const key in this.values) this.values[key] = 0;
    for (const key in this.tweenedValues) this.tweenedValues[key] = 0;

    this.$nextTick(() => {
      this.isMounted = true;

      this.onViewportChange();

      window.addEventListener("DOMContentLoaded", this.onViewportChange, false);
      window.addEventListener("load", this.onViewportChange, false);
      window.addEventListener("scroll", this.onViewportChange, false);
      window.addEventListener("resize", this.onViewportChange, false);
    });
  }

  beforeDestroy() {
    window.removeEventListener(
      "DOMContentLoaded",
      this.onViewportChange,
      false
    );
    window.removeEventListener("load", this.onViewportChange, false);
    window.removeEventListener("scroll", this.onViewportChange, false);
    window.removeEventListener("resize", this.onViewportChange, false);
  }

  changeHighlight() {
    if (this.highlighted < 3) {
      this.highlighted++;
    } else {
      this.highlighted = 0;
    }
  }
}
